const codeS = [
  { code: 401, message: '未授权' },
  { code: 404, message: '请求地址不存在' },
  { code: 1000, message: '后端服务内部错误' },
  { code: 1001, message: '参数错误' },
  { code: 1002, message: '账号在其他地方登录' },
  { code: 1003, message: 'APP通用参数错误' },
  { code: 1004, message: '请求ID无效' },
  { code: 1005, message: '签名错误' },
  { code: 1010, message: '聊天太频繁，被限制使用' },
  { code: 10001, message: '提示词不能为空' },
  { code: 10002, message: '提示词超过最大长度' },
  { code: 10003, message: '回复为空' },
  { code: 10004, message: '用户名参数无效' },
  { code: 10005, message: '密码参数无效' },
  { code: 10006, message: '用户名已被注册' },
  { code: 10007, message: '邮箱无效' },
  { code: 10008, message: '账号无效' },
  { code: 10009, message: '手机号无效' },
  { code: 10010, message: '验证码无效' },
  { code: 10011, message: '订单号无效' },
  { code: 10012, message: '提示词中的message不能为空' },
  { code: 10013, message: '订单无效' },
  { code: 10014, message: 'Paypal订单确认失败' },
  { code: 10015, message: 'token无效' },
  { code: 10016, message: '提示词token长度无效' },
  { code: 10017, message: '用户ID无效' },
  { code: 10018, message: '等级ID无效' },
  { code: 10019, message: '配置ID无效' },
  { code: 10020, message: '数据不存在' },
  { code: 10025, message: '验证码不能为空' },
  { code: 10026, message: '验证码无效' },
  { code: 10027, message: '文本长度不能为空' },
  { code: 10028, message: '文心一格配置不存在' },
  { code: 10029, message: '图片任务ID无效' },
  { code: 10030, message: '图片描述词有误（1-100个字符）' },
  { code: 10031, message: '图片风格无效' },
  { code: 10032, message: '图片规格无效' },
  { code: 10033, message: '图片数量无效' },
  { code: 10034, message: '头像地址不能为空' },
  { code: 10035, message: '验证码参数无效' },
  { code: 10036, message: '通知内容无效' },
  { code: 10037, message: '模型无效' },
  { code: 10038, message: '图片尺寸无效' },
  { code: 10039, message: '相似度参数无效' },
  { code: 10040, message: '图片ID无效' },
  { code: 10041, message: '阿里云sfs参数必传' },
  { code: 10042, message: '用户昵称不能为空' },
  { code: 10043, message: '套餐包ID无效' },
  { code: 10044, message: '模板ID无效' },
  { code: 10044, message: '用户昵称无效' },
  { code: 20001, message: '请求OpenAi失败' },
  { code: 20002, message: '注册数量达到限制' },
  { code: 20003, message: '用户不存在' },
  { code: 20004, message: '密码错误' },
  { code: 20005, message: 'VIP等级不存在' },
  { code: 20006, message: '用户请求次数不足' },
  { code: 20007, message: '不能购买低于自己等级的套餐' },
  { code: 20008, message: '创建订单失败' },
  { code: 20009, message: '创建支付失败' },
  { code: 20010, message: '支付签名无效' },
  { code: 20011, message: '支付结果有误' },
  { code: 20012, message: '支付回调订单信息有误' },
  { code: 20013, message: '订单无效' },
  { code: 20014, message: '订单支付金额有误' },
  { code: 20015, message: '请求验证码太过频繁' },
  { code: 20016, message: '获取验证码失败' },
  { code: 20017, message: '验证码错误' },
  { code: 20018, message: '邮箱或手机号被占用' },
  { code: 20019, message: '已废弃' },
  { code: 20020, message: '订单状态有误' },
  { code: 20021, message: '订单重复发起支付' },
  { code: 20022, message: '支付回调签名错误' },
  { code: 20023, message: '支付回调处理失败' },
  { code: 20024, message: '小程序认证失败' },
  { code: 20025, message: '小程序登录失败' },
  { code: 20026, message: '注册失败' },
  { code: 20027, message: '微信二维码授权失败' },
  { code: 20028, message: '微信扫码登录失败' },
  { code: 20029, message: '微信授权账号已经存在' },
  { code: 20030, message: '账号异常' },
  { code: 20031, message: '会员不能注销' },
  { code: 20032, message: '对话包含敏感词' },
  { code: 20033, message: '注册太过频繁' },
  { code: 20034, message: '手机号已被占用' },
  { code: 20035, message: '邮箱已被占用' },
  { code: 20036, message: '文心一格认证失败' },
  { code: 20037, message: '计算文本token长度失败' },
  { code: 20038, message: '请求文心一格创建图片失败' },
  { code: 20039, message: 'gpt4请求次数不足' },
  { code: 20040, message: '请求太频繁，稍后再试' },
  { code: 20041, message: '下载文件失败' },
  { code: 20042, message: '验证码初始化客户端失败' },
  { code: 20043, message: '验证码验证失败' },
  { code: 20044, message: '通知不存在' },
  { code: 20045, message: '生成图片失败' },
  { code: 20046, message: '渠道管理员密码错误' },
  { code: 20047, message: '设备不存在' },
  { code: 20048, message: '当前API不支持在此应用平台请求' },
  { code: 20049, message: '仅支持会员用户' },
  { code: 20050, message: '未安装插件' },
  { code: 20051, message: '初始化阿里云客户端失败' },
  { code: 20052, message: '请求阿里云失败' },
  { code: 20053, message: '请求搜索失败' },
  { code: 20054, message: '已经领取过插件试用' },
  { code: 20055, message: '插件使用已过期' },
  { code: 20056, message: '输出内容包含敏感信息' },
  { code: 50001, message: '今日广告奖励超出限制' }
]
export default codeS
