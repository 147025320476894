import { themes } from './model'
// 修改页面中的样式变量值
const changeStyle = (obj) => {
  const appElement = document.getElementById('app')
  for (const key in obj) {
    appElement.style.setProperty(`--${key}`, obj[key])
  }
}

// 改变主题的方法
export const setTheme = (themeName) => {
  localStorage.setItem('theme', themeName) // 保存主题到本地，下次进入使用该主题
  const themeConfig = themes[themeName]
  // 如果有主题名称，那么则采用我们定义的主题
  if (themeConfig) {
    changeStyle(themeConfig) // 改变样式
  } else {
    changeStyle(themeConfig)
  }
}
