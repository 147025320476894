import { chatConfig } from '@/api/login'

console.log('config')
const state = {
  // 配置信息
  config: JSON.parse(sessionStorage.getItem('config')) || {},
  // 来源端口
  device: localStorage.getItem('device') || 'web' // web:网页 desktop:桌面版
}

const getters = {
  isDesktop (state) {
    return state.device === 'desktop'
  }
}

const mutations = {
  setConfig (state, value) {
    state.config = value
    sessionStorage.setItem('config', JSON.stringify(value))
  },
  setDevice (state, value) {
    state.device = value
    localStorage.setItem('device', value)
  }
}
const actions = {
  // 获取配置信息
  async getConfigInfo ({ commit }) {
    try {
      // if (!this.$store.state.token) return
      const { data } = await chatConfig()
      commit('setConfig', Object.freeze(data))
    } catch (err) {
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
