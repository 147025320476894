export default {
  // 监测用户 设备是否未移动设备
  isMobile  () {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
      navigator.userAgent
    )
  },
  // 添零
  addZero (num) {
    if (num < 10 && num.toString().length === 1) {
      return `0${num}`
    } else {
      return num
    }
  },
  // 千分位符
  Thousands (num) {
    let str = null
    if (num) {
      str = num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
    return str || '0.00'
  },
  // 时间戳转换时间
  convertTime (time) {
    const date = new Date(time)
    const year = date.getFullYear() // 年份
    const month = date.getMonth() + 1 // 月份，需要加1
    const day = date.getDate() // 日期
    const hour = date.getHours() // 小时
    const minute = date.getMinutes() // 分钟
    const second = date.getSeconds() // 秒钟
    return {
      year,
      month,
      day,
      hour,
      minute,
      second
    }
  },
  formatDateTime (date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${[year, month, day].map(num => num < 10 && num.toString().length === 1 ? `0${num}` : num).join('-')}`
  }
}
