const state = {
  isShow: false,
  sign: 1, // 1==>登录 2==>注册 3==>找回密码  4==>设置新密码 5==>微信群
  loginLoad: false // H5 公众号登录中
}
const mutations = {
  setIsShow (state, value) {
    state.isShow = value
  },
  setSign (state, value) {
    state.sign = value
  },
  setLoginLoad (state, value) {
    state.loginLoad = value
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
