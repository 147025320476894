import Vue from 'vue'
import VueRouter from 'vue-router'
import utils from '@/utils/utils'
import store from '@/store/index'

Vue.use(VueRouter)
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
// 重写push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const pcRoutes = [
  {
    path: '/',
    redirect: '/chat_room'
  },
  {
    path: '/chat_room',
    name: '聊天室',
    meta: { keepAlive: false },
    component: () => import('@/views/chat_room/chat_room.vue'),
    children: [
      {
        path: '/',
        redirect: '/ask_answer'
      },
      {
        path: '/ask_answer',
        name: '问答区',
        component: () => import('@/views/chat_room/ask_answer/ask_answer.vue')
      },
      {
        path: '/help_center',
        name: '帮助中心',
        component: () => import('@/views/chat_room/help_center/help_center.vue')
      },
      {
        path: '/vip_card',
        name: '会员卡片',
        component: () => import(/* webpackPrefetch:true */'@/views/chat_room/vip_card/vip_card.vue')
      },
      // {
      //   path: '/upgrade_service',
      //   name: '升级服务',
      //   component: () => import('@/views/chat_room/upgrade_service/upgrade_service.vue')
      // },
      {
        path: '/about_us',
        name: '关于我们',
        component: () => import('@/views/chat_room/about_us/about_us.vue')
      },
      {
        path: '/office_inlet',
        name: 'office 助手',
        component: () => import(/* webpackPrefetch:true */'@/views/chat_room/office_inlet/office_inlet.vue')
      },
      {
        path: '/user_center',
        name: '用户中心',
        component: () => import('@/views/chat_room/user_center/user_center.vue')
      },
      // {
      //   path: '/usage_scenario',
      //   name: '应用场景',
      //   meta: { keepAlive: false },
      //   component: () =>
      //     import('@/views/chat_room/usage_scenario/usage_scenario.vue')
      // },
      // {
      //   path: '/expert_role',
      //   name: '专家角色',
      //   meta: { keepAlive: false },
      //   component: () => import('@/views/chat_room/expert_role/expert_role.vue')
      // },
      {
        path: '/sceneList',
        name: '应用场景列表',
        meta: { keepAlive: false },
        component: () => import('@/views/chat_room/sceneList/sceneList.vue')
      },
      // {
      //   path: '/usage_scenario/detail',
      //   name: '应用场景详情',
      //   meta: { keepAlive: false },
      //   component: () =>
      //     import('@/views/chat_room/usage_scenario/detail/detail.vue')
      // },
      // {
      //   path: '/plugin_center',
      //   name: '插件中心',
      //   meta: { keepAlive: false },
      //   component: () =>
      //     import('@/views/chat_room/plugin_center/plugin_center.vue')
      // },
      {
        path: '/orange',
        name: '4.0加油包',
        meta: { keepAlive: false },
        component: () => import('@/views/chat_room/chatOrange/chatOrange.vue')
      }
    ]
  },
  {
    path: '/chat_pdf',
    name: 'pdf',
    meta: { keepAlive: false },
    component: () => import(/* webpackPrefetch:true */'@/views/chat_pdf/chat_pdf.vue')
  },
  {
    path: '/chat_ppt',
    name: 'ppt',
    meta: { keepAlive: false },
    component: () => import('@/views/chat_ppt/chat_ppt.vue')
  },
  {
    path: '/test',
    meta: { keepAlive: false },
    component: () => import('@/views/test/test.vue')
  },
  {
    path: '*',
    redirect: '/chat_room'
  }
]
const ydRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: '登录',
    meta: { keepAlive: false },
    component: () => import('@/mobile/login/login.vue'),
    children: [
      {
        path: '/',
        redirect: '/sign_in'
      },
      {
        path: '/sign_in',
        name: '登录',
        meta: {
          fatherPath: '/login'
        },
        component: () => import('@/mobile/login/sign_in/sign_in.vue')
      },
      {
        path: '/register',
        name: '注册',
        meta: {
          fatherPath: '/login'
        },
        component: () => import('@/mobile/login/register/register.vue')
      },
      {
        path: '/forgot_password',
        name: '找回密码',
        meta: {
          fatherPath: '/login'
        },
        component: () =>
          import('@/mobile/login/forgot_password/forgot_password.vue')
      },
      {
        path: '/set_password',
        name: '设置密码',
        meta: {
          fatherPath: '/login'
        },
        component: () => import('@/mobile/login/set_password/set_password.vue')
      }
    ]
  },
  {
    path: '/home',
    name: 'H5首页',
    meta: { keepAlive: false },
    component: () => import('@/mobile/home/home.vue'),
    children: [
      {
        path: '/',
        redirect: '/chat_room'
      },
      {
        path: '/chat_room',
        name: '聊天室',
        meta: { keepAlive: false },
        component: () => import('@/mobile/home/chat_room/chat_room.vue')
      },
      {
        path: '/mine_module',
        name: '我的',
        meta: { keepAlive: false },
        component: () => import('@/mobile/home/mine_module/mine_module.vue')
      },
      {
        path: '/usage_scenario',
        name: '应用场景',
        meta: { keepAlive: false },
        component: () =>
          import('@/mobile/home/usage_scenario/usage_scenario.vue')
      },
      {
        path: '/usage_scenario/detail',
        name: '应用场景详情',
        meta: { keepAlive: false },
        component: () =>
          import('@/mobile/home/usage_scenario/detail/detail.vue')
      }
    ]
  },
  {
    path: '/help_center',
    name: '帮助中心',
    meta: { keepAlive: false },
    component: () => import('@/mobile/help_center/help_center.vue')
  },
  {
    path: '/user_center',
    name: '用户中心',
    meta: { keepAlive: false },
    component: () => import('@/mobile/home/user_center/user_center.vue')
  },
  {
    path: '/upgrade_service',
    name: '升级服务',
    meta: { keepAlive: false },
    component: () => import('@/mobile/upgrade_service/upgrade_service.vue')
  },
  {
    path: '/about_us',
    name: '关于我们',
    meta: { keepAlive: false },
    component: () => import('@/mobile/about_us/about_us.vue')
  },
  {
    path: '/setUp',
    name: '设置',
    meta: { keepAlive: false },
    component: () => import('@/mobile/setUp/setUp.vue')
  },
  {
    path: '/test',
    meta: { keepAlive: false },
    component: () => import('@/mobile/test/test.vue')
  },
  {
    path: '/questions',
    meta: { keepAlive: false },
    component: () => import('@/mobile/questions/questions.vue')
  },
  // {
  //   path: '/notice',
  //   meta: { keepAlive: false },
  //   component: () => import('@/mobile/notice/notice.vue')
  // },
  {
    path: '*',
    redirect: '/home'
  }
]
const routes = utils.isMobile() ? ydRoutes : pcRoutes

const router = new VueRouter({
  routes
})

// 前置导航守卫
router.beforeEach((to, from, next) => {
  if (!store.state.token) {
    // pc方案
    if (!utils.isMobile()) {
      if (to.path === '/upgrade_service' || to.path === '/user_center') {
        store.commit('login/setSign', 0)
        store.commit('login/setIsShow', true)
        next('/chat_room')
      }
    } else {
      if (to.meta.fatherPath !== '/login') {
        const cid = to.query.cid
        if (cid) {
          const path = '/login' + cid ? `?cid=${cid}` : ''
          next(path)
        } else {
          next('/login')
        }
      }
    }
  } else {
    // 移动端
    if (utils.isMobile()) {
      if (
        to.path === '/sign_in' ||
        to.path === '/register' ||
        to.path === '/forgot_password' ||
        to.path === '/set_password'
      ) {
        next('/chat_room')
      }
    }
  }
  // if (to.path !== '/offline') {
  //   next('/offline')
  // } else {
  //   next()
  // }
  next()
})
// 后置导航守卫
router.afterEach((to, from) => {})
export default router
