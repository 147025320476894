import Vue from 'vue'
import Vuex from 'vuex'
// import VueResource from 'vue-resource'
import login from './login'
import mobile from './mobile'
import user from './user'
import plugin from './plugin'
import pdf from './pdf'
import expert from './expert'
import config from './config'
import { getChatUserChatRecord, setChatUserChatRecord, getQiniuToken } from '@/api/plugin'
import * as qiniu from 'qiniu-js'
// import md5 from 'js-md5'
import utils from '@/utils/utils'
import router from '@/router/index'
import $i18n from '@/assets/language/index'
import { debounce } from '@/utils/tool'
import Tip from '@/utils/message'
import { chatUserInfo } from '@/api/login'
import { setTheme } from '../theme/theme'
Vue.use(Vuex)
// Vue.use(VueResource)
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
    helpInfo: JSON.parse(localStorage.getItem('helpInfo')) || {},
    // 所有聊天记录总和
    AllchatRecord: [],
    // 当前处于哪个聊天室
    roomId: null,
    // 当前读取的用户聊天记录名称
    chatRecordName: null,
    // 邀请码
    InvitationCode: null,
    // 来源渠道
    cid: localStorage.getItem('cid') || null,
    // 版本号
    edition: localStorage.getItem('edition') || null,
    // 版本
    version: 1, // 1 通用版 2 凡文版 // 3 百度推广
    // 备案号
    filings: '',
    // 聊天记录是否变更
    isRenew: localStorage.getItem('isRenew') || false,
    // 退出登录聊天记录是否上传完成
    isUpload: false,
    // 云端上传loading,
    uploading: false,
    // 是否展示客服
    isContact: false,
    // 当前语言
    lang: localStorage.getItem('lang') || ((['zh', 'en', 'ru', 'fa'].includes(navigator.language.substring(0, 2))) ? navigator.language.substring(0, 2) : '') || 'en',
    isFull: false,
    // 默认模型
    model: localStorage.getItem('model') || '3.5',
    // 模板
    simple: '',
    theme: localStorage.getItem('theme') || 'default'

  },
  getters: {},
  mutations: {
    setThemeStore (state, theme) {
      setTheme(theme)
      state.theme = theme
    },
    setSimple (state, value) {
      state.simple = value
    },
    setToken (state, value) {
      state.token = value
      try {
        localStorage.setItem('token', value)
      } catch (err) {
        state.isFull = true
      }
    },
    setUserInfo (state, value) {
      state.userInfo = value
      try {
        localStorage.setItem('userInfo', JSON.stringify(value))
      } catch (err) {
        state.isFull = true
      }
    },
    setHelpInfo (state, value) {
      state.helpInfo = value
      try {
        localStorage.setItem('hlepInfo', JSON.stringify(value))
      } catch (err) {
        state.isFull = true
      }
    },
    // 存储聊天记录
    setAllchatRecord (state, value, allowThrow = false) {
      state.AllchatRecord = value
      try {
        localStorage.setItem(state.chatRecordName, JSON.stringify(value))
      } catch (err) {
        state.isFull = true
        if (allowThrow) throw new Error('failed to save')
      }
    },
    addPropertyToRecord (state, { index, key, value }) {
      Vue.set(state.AllchatRecord[index], key, value)
    },
    setIsFull (state, value) {
      state.isFull = value
    },
    setRoomId (state, value) {
      state.roomId = value
    },
    setAllUserChatRecord (state, value) {
      state.AllUserChatRecord = value
    },
    setInvitationCode (state, value) {
      state.InvitationCode = value
    },
    setCid (state, value) {
      state.cid = value
      try {
        localStorage.setItem('cid', value)
      } catch (err) {
        state.isFull = true
      }
    },
    setEdition (state, value) {
      state.edition = value
      try {
        localStorage.setItem('edition', value)
      } catch (err) {
        state.isFull = true
      }
    },
    setFilings (state, value) {
      state.filings = value
    },
    setChatRecordName (state, value) {
      state.chatRecordName = value
    },
    setIsRenew (state, value) {
      state.isRenew = value
      try {
        localStorage.setItem('isRenew', value)
      } catch (err) {
        state.isFull = true
      }
    },
    setIsUpload (state, value) {
      state.isUpload = value
    },
    setUploading (state, value) {
      state.uploading = value
    },
    setIsContact (state, value) {
      state.isContact = value
    },
    setLang (state, val) {
      state.lang = val
    },
    SetModel (state, val) {
      state.model = val
      localStorage.setItem('model', val)
    }
  },
  actions: {
    // 退出登录
    signOut: debounce(({ commit, state, dispatch }, type) => {
      // 用户云端同步如果没有选择自动备份则退出不备份
      const index = state.plugin.pluginInfo.findIndex(i => i.id === 4)
      const isAuto = index !== -1 ? state.plugin.pluginInfo[index].params.is_auto : false
      if (type === 'save' && isAuto) {
        commit('setUploading', true)
        dispatch('automaticBackups')
        let time = null
        time = setInterval(() => {
          if (state.isUpload) {
            clearInterval(time)
            dispatch('clearState')
          }
        }, 100)
      } else {
        dispatch('clearState')
      }
    }, 300),
    // 清除缓存
    clearState ({ commit }) {
      commit('setUserInfo', '')
      commit('setToken', '')
      commit('SetModel', '')
      commit('setUploading', false)
      commit('setIsUpload', false)
      if (utils.isMobile()) {
        router.push('/login')
      } else {
        router.push('/')
      }
      commit('plugin/setPluginInfo', [])
      // 获取当前页面的 URL
      const urlString = window.location.href
      // 判断 URL 中是否有参数
      if (urlString.indexOf('?') !== -1) {
        // 如果有参数，则将参数部分清空
        const baseUrl = urlString.substring(0, urlString.indexOf('?'))
        window.history.replaceState(null, null, baseUrl)
      }
      localStorage.setItem('userInfo', null)
      localStorage.setItem('token', '')
    },
    // 自动备份
    automaticBackups ({ state, dispatch, commit }) {
      // 合并聊天记录
      const mergeChat = () => {
        // 合并后的聊天记录
        let arr = []
        // 获取用户云端聊天记录
        return dispatch('getChat').then((res) => {
          if (!Array.isArray(res)) {
            commit('setIsUpload', true)
            throw new Error('record is not Array')
          }
          const coludChat = res
          // 用户本地聊天记录
          const deviceChat = state.AllchatRecord
          // console.log(deviceChat, '本地聊天记录')
          // console.log(coludChat, '云端聊天记录')
          if (coludChat) {
            arr = [...deviceChat, ...coludChat]
          } else {
            arr = deviceChat
          }
          // 去除重复id项后的聊天记录
          const result = arr.filter((item, index, self) =>
            index === self.findIndex(obj => obj.id === item.id)
          )
          return result
        })
      }
      mergeChat().then((res) => {
        if (res) {
          // 去除空项并上传云端
          // dispatch('emptyChatRoom', res).then((res) => {
          //   dispatch('cloudUpload', res)
          // })
          dispatch('cloudUpload', res)
          // 存储上次备份的时间
          localStorage.setItem('backupsTime', Date.now())
        }
      }).catch((err) => {
        if (err.message === 'getChatRecord Failed') {
          Tip({
            message: '获取用户聊天记录失败',
            type: 'error'
          })
        } else if (err.message === 'record is not Array') {
          Tip({
            message: '云端记录格式错误',
            type: 'error'
          })
        }
        commit('setIsUpload', true)
      })
    },
    // 获取用户聊天记录
    async getChat () {
      try {
        const { data } = await getChatUserChatRecord()
        if (!data.path) return
        return fetch(data.path, {
          cache: 'no-cache'
        }).then((res) => {
          return res.json()
        }).then((res) => {
          return res
        }).catch(() => {})
      } catch (err) {
        return Promise.reject(new Error('getChatRecord Failed'))
      }
    },
    // 去除空聊天室
    emptyChatRoom ({ state }, res) {
      let arr = JSON.parse(JSON.stringify(res))
      arr = arr.filter((item) => item.chatRecord.length > 0)
      return arr
    },
    // 云端上传
    cloudUpload ({ dispatch, commit, state }, arr) {
      // console.log(arr, 'arr')
      dispatch('emptyChatRoom', arr).then(async (res) => {
        if (res.length <= 0) return
        // this.uploadLoading = true
        const file = new File(
          [
            JSON.stringify(res)
          ],
          'test.json', {
            type: 'text/plain'
          }
        )
        // console.log(file, 'file')
        const putExtra = {
          fname: file.name,
          params: {},
          mimeType: 'text/*'
        }
        const config = {
          useCdnDomain: true // cdn加速
          // region: 'up-z2.qiniup.com' // 区域
        }
        const bucket = 'xiaoju-chat-img'
        const { default: md5 } = await
        import(/* webpackChunkName:'md5' */ 'js-md5')
        dispatch('getQiqiuToken', `chat-record/${md5(state.chatRecordName)}.${file.name.split('.')[1]}`).then(res => {
          // const _this = this
          const observable = qiniu.upload(
            file,
            'chat-record/' + md5(state.chatRecordName) + '.' + file.name.split('.')[1],
            res.token,
            putExtra,
            config
          )
          observable.bucket = bucket
          const observer = {
            next (res) {
              // console.log(res, 'res')
            },
            error (err) {
              console.log(err)
              throw new Error('upload QiqiuToken failed')
              // this.uploadLoading = false
              // _this.$message({
              //   message: '上传失败!!! 请重新上传',
              //   type: 'error'
              // })
            },
            complete (res) {
              // https://images.chat-gpt-ai.cn/
              dispatch('updateChat', `https://img2.anycellreport.com/${res.key}`)
            }
          }
          observable.subscribe(observer)
        }).catch(err => {
          if (err.message === 'getQiniuToken failed') {
            Tip({
              message: '获取七牛云文件上传token失败',
              type: 'error'
            })
          } else if (err.message === 'upload QiqiuToken failed') {
            Tip({
              message: '七牛云上传失败',
              type: 'error'
            })
          }
          commit('setIsUpload', true)
        })
      })
    },
    // 更新用户聊天记录
    updateChat ({ state, commit }, url) {
      setChatUserChatRecord({
        path: url
      }).then((res) => {
        commit('setIsUpload', true)
        // this.uploadLoading = false
      }).catch(() => {
        // this.uploadLoading = false
        // this.$message({
        //   message: '上传失败!!! 请重新上传',
        //   type: 'error'
        // })
      })
    },
    // 获取七牛云文件上传token
    async getQiqiuToken ({ state }, filename) {
      try {
        const bucket = 'xiaoju-chat-img'
        const res = await getQiniuToken({
          filename,
          bucket
        })
        return res.data
      } catch (err) {
        return Promise.reject(new Error('getQiniuToken failed'))
      }
    },
    // 下载/恢复 聊天记录
    restoreChat ({ dispatch, commit, state }) {
      // 云端聊天记录
      let cloudChat = null
      // 合并后新的聊天记录
      let newChat = []
      dispatch('getChat').then((res) => {
        if (res && res.length) {
          cloudChat = res
          newChat = [...state.AllchatRecord, ...cloudChat]
          const result = newChat.reduce((acc, curr) => {
            const index = acc.findIndex((item) => item.id === curr.id)
            if (index < 0) {
              acc.push(curr)
            }
            return acc
          }, [])
          commit('setAllchatRecord', result)
        }
      })
    },
    async getUserInfo ({ commit }) {
      try {
        const { data } = await chatUserInfo()
        commit('setUserInfo', data)
        return data
      } catch (err) {
        console.log(err)
      }
    },
    // 语言切换
    switchLanguage (store, val) {
      if (val === 1) {
        $i18n.locale = 'en'
        store.commit('setLang', 'en')
        localStorage.setItem('lang', 'en') // 这样保存起来可以防止刷新页面就回到了初始值
      } else if (val === 2) {
        $i18n.locale = 'zh'
        store.commit('setLang', 'zh')
        localStorage.setItem('lang', 'zh') // 这样保存起来可以防止刷新页面就回到了初始值
      } else if (val === 3) {
        $i18n.locale = 'fa'
        store.commit('setLang', 'fa')
        localStorage.setItem('lang', 'fa') // 这样保存起来可以防止刷新页面就回到了初始值
      } else if (val === 4) {
        $i18n.locale = 'ru'
        store.commit('setLang', 'ru')
        localStorage.setItem('lang', 'ru') // 这样保存起来可以防止刷新页面就回到了初始值
      }
      // 刷新页面
      location.reload()
    }
  },
  modules: {
    login,
    mobile,
    user,
    plugin,
    pdf,
    expert,
    config
  }
})
