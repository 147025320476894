export const themes = {
  default: {
    chat_aside_bg_color: '#181818',
    chat_aside_border_color: '#313535',
    chat_aside_logo_title_border: '#262626',
    chat_aside_logo_title_h1: '#fff',
    chat_aside_logo_bg: '#363636',
    chat_aside_ability_border: '#313535FF',
    chat_aside_list_span: '#fff',
    chat_aside_list_bg: '#2b2c33',
    chat_aside_list_bottom_color: '#d1d1d1',
    chat_aside_list_bottom_hovercolor: '#fff',
    chat_aside_list_bottom_vip_color: '#515151',
    chat_aside_list_bottom_download: '#272727FF',
    chat_aside_list_bottom_download_bor: '#414141FF',
    chat_aside_download_color: '#fff',
    chat_aside_download_bg: '#515151',
    chat_aside_pop_up: '#1f1f1f',
    chat_aside_pop_up_color: '#fff',
    chat_aside_pop_up_hover: '#2b2c33',
    chat_aside_pop_up_info_vip: '#2b2b2b',
    chat_aside_pop_up_info_vip_color: '#fff',
    chat_aside_pop_up_info_vip_color1: '#b9b9b9',
    chat_aside_pop_up_info_vip_color2: '#fff',
    chat_notice_color: '#fff',
    chat_lang_bg: '#393939',
    chat_lang_color: '#fff',

    office_color: '#fff',
    office_bg_color: '#fff',

    chatbar_bg: '#383940FF',
    chatbar_color: '#e5e5e5',
    chatbar_list_color: '#fff',
    chatbar_i_color: '#CCCCCC',
    chatbar_i_hover_color: '#e3e3e3',
    chatbar_size_color: '#d1d1d1',
    chatbar_list_bg: '#373737',
    chatbar_hover_bg: '#2a2a2a',
    chatbar_list_scr: '#8d93a8',
    chatbar_before: '#262626',

    scene_i_color: '#b0b7c3',
    scene_search_color: '#ffffff4d',
    scene_tab_color: '#ffffff4d',
    scene_tab_hover_color: '#ffffff29',
    scene_lisy_color: '#fff',
    scene_list_color: '#b0b7c3',
    scene_list_color_bg: '#ffffff33',
    scene_list_bg: '#18233b',
    scene_list_border: '#334155FF',

    room_title_color: '#fff',
    room_listModel_bor: '#ffffff4d',
    room_listModel_hover: '#ffffff29',
    room_input_border: '#ffffff80',
    room_input_color: '#fff',
    room_prompt_border: '#5a5a5a',
    room_prompt_top_bg: '#232323',
    room_prompt_top_before: '#ffffff80',
    room_prompt_li_border: '#242424',
    room_prompt_li_color: '#e3e3e3',
    room_prompt_li_p_color: '#768396',
    room_more_border: '#ffffff80',
    room_more_color: '#ffffff80',
    room_model_pop_bg: '#0f172a',
    icon_guanbi1: '#d8d8d8',
    room_model_input_bg: '#0f172a',
    room_model_input_color: '#fff',
    room_model_input_border: '#ffffff4d',
    room_model_tab_bor: '#334155ff',
    room_model_list_color: '#fff',
    room_model_lists_color: '#b0b7c3',
    room_model_span_color: '#fff',
    room_model_span2_color: '#b0b7c3',
    room_chart_bu_bg: '#334155',
    room_chart_bu_color: '#fff',
    room_chart_hover_color: '#fff',
    room_carousel_color: '#fff',
    room_carousel_bg: '#334155',
    room_carousel_bg_border: '#334155',
    room_carousel_border: '#181818',
    room_expert_color: '#fff',
    room_expert_bg: '#1d2335',
    room_chat_desc_color: '#fff',
    chat_color: '#fff',
    chat_tops_color: '#fff',
    user_bg: '#dae4ec',
    user_bg_color: '#181818',
    about_bg: '#fff',
    bg_color: '#0f172a',
    logo_bg_color: '#181818',
    service: '#fff'

  },
  dark: {
    chat_aside_bg_color: '#fff',
    chat_aside_border_color: '#0000001A',
    chat_aside_logo_title_border: '#0000001A',
    chat_aside_logo_title_h1: '#333333FF',
    chat_aside_logo_bg: '#f6f6f6',
    chat_aside_ability_border: '#0000001A',
    chat_aside_list_span: '#181818FF',
    chat_aside_list_bg: '#F5F7FF',
    chat_aside_list_bottom_color: '#272727FF',
    chat_aside_list_bottom_hovercolor: '#1F1F1FFF',
    chat_aside_list_bottom_vip_color: 'linear-gradient(90deg, #b94dcc 0%, #4e89cc 100%)',
    chat_aside_list_bottom_download: '#F1F1F1FF',
    chat_aside_list_bottom_download_bor: '#ccc',
    chat_aside_download_color: '#272727FF',
    chat_aside_download_bg: '#ccc',
    chat_aside_pop_up: '#F2F4F8',
    chat_aside_pop_up_color: '#333333',
    chat_aside_pop_up_hover: '#cfcbcb',
    chat_aside_pop_up_info_vip: '#EAEAEF',
    chat_aside_pop_up_info_vip_color: '#333333',
    chat_aside_pop_up_info_vip_color1: '#999',
    chat_aside_pop_up_info_vip_color2: '#333333',
    chat_notice_color: '#333',
    chat_lang_bg: '#fafafa',
    chat_lang_color: '#333',

    office_color: '#333',
    office_bg_color: '#F7F7F7',

    chatbar_bg: '#F7F7F7',
    chatbar_color: '#919192',
    chatbar_list_color: '#181818',
    chatbar_i_color: '#181818',
    chatbar_i_hover_color: '#333',
    chatbar_size_color: '#181818',
    chatbar_list_bg: '#F7F7F7FF',
    chatbar_hover_bg: '#F7F7F7FF',
    chatbar_list_scr: '#dadada',
    chatbar_before: '#e5e5e5',

    scene_i_color: '#999999',
    scene_search_color: '#b2b2b2',
    scene_tab_color: '#cccccc',
    scene_tab_hover_color: '#86868629',
    scene_lisy_color: '#333',
    scene_list_color: '#666',
    scene_list_color_bg: '#eaeaea',
    scene_list_bg: '#f7f7f7',
    scene_list_border: '#e5e5e5',

    room_title_color: '#333',
    room_listModel_bor: '#cccccc',
    room_listModel_hover: '#f7f7f7',
    room_input_border: '#b2b2b2',
    room_input_color: '#333',
    room_prompt_border: '#e5e5e5',
    room_prompt_top_bg: '#f4f4f4',
    room_prompt_top_before: '#00000027',
    room_prompt_li_border: '#f0f0f0',
    room_prompt_li_color: '#333',
    room_prompt_li_p_color: '#666',
    room_more_border: '#ccc',
    room_more_color: '#999',
    room_model_pop_bg: '#fff',
    icon_guanbi1: '#333',
    room_model_input_bg: '#fff',
    room_model_input_color: '#666',
    room_model_input_border: '#b2b2b2',
    room_model_tab_bor: '#d6d6d6',
    room_model_list_color: '#2772F0',
    room_model_lists_color: '#999',
    room_model_span_color: '#333',
    room_model_span2_color: '#666',
    room_chart_bu_bg: '#eaeaea',
    room_chart_bu_color: '#333',
    room_chart_hover_color: '#2d67ed',
    room_carousel_color: '#333',
    room_carousel_bg: '#fff',
    room_carousel_bg_border: '#e5e5e5',
    room_carousel_border: '#DBE1EB',
    room_expert_color: '#333',
    room_expert_bg: '#F9F9F9',
    room_chat_desc_color: '#333',
    chat_color: '#333',
    chat_tops_color: '#333',
    user_bg: '#f8f8f8',
    user_bg_color: '#fff',
    about_bg: '#F9F9F9',
    bg_color: '#fff',
    logo_bg_color: '#f6f6f6',
    service: '#323E4E'

  }
}
