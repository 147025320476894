const state = {
  // 是否开启小窗口进行绑定微信
  isBinding: localStorage.getItem('isBinding') || false,
  // 微信绑定code
  wxCode: localStorage.getItem('wxCode') || null
}
const mutations = {
  setIsBinding (state, val) {
    state.isBinding = val
  },
  setWxCode (state, val) {
    state.wxCode = val
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
