import Vue from 'vue'
// 使用插件
import VueI18n from 'vue-i18n'
import enEl from 'element-ui/lib/locale/lang/en'
import EnEl from 'vant/lib/locale/lang/en-US'
import zhEl from 'element-ui/lib/locale/lang/zh-TW'
import ZhEl from 'vant/lib/locale/lang/zh-TW'
// import ruEl from 'element-ui/lib/locale/lang/ru-RU'
// import RuEl from 'vant/lib/locale/lang/ro-RO'
// import faEl from 'element-ui/lib/locale/lang/fa'
// import FaEl from 'vant/lib/locale/lang/fr-FR'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)

// 获取浏览器语言
const navLang = navigator.language.substring(0, 2)
const supportedLangs = ['zh', 'en', 'ru', 'fa']
const localLang = supportedLangs.includes(navLang) ? navLang : ''
const lang = localStorage.getItem('lang') || localLang || 'en'
const i18n = new VueI18n({
  // locale: localStorage.getItem('lang') || 'en', // 语言标识,第一次登录默认是英文
  locale: lang,
  messages: {
    zh: { ...require('./language/local_zh'), ...zhEl, ...EnEl }, // 繁体中文
    en: { ...require('./language/local_en'), ...enEl, ...ZhEl } // 英语
    // ru: { ...require('./language/local_ru'), ...ruEl, ...RuEl }, // 俄语
    // fa: { ...require('./language/local_fa'), ...faEl, ...FaEl } // 波斯语
    // 要多少语言就自己添加多少
  }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n
