import request from '@/utils/request'

// 登录
export function chatLogin (data) {
  return request({
    url: '/chat/login',
    method: 'post',
    data
  })
}
// 注册
export function chatRegister (data) {
  return request({
    url: '/chat/register',
    method: 'post',
    data
  })
}

// 用户信息
export function chatUserInfo (params) {
  return request({
    url: '/chat/user/info',
    method: 'get',
    params
  })
}
// 获取验证码
export function chatCaptchaCode (data) {
  return request({
    url: '/chat/captcha-code',
    method: 'post',
    data
  })
}

// 重置密码
export function chatResetPass (data) {
  return request({
    url: '/chat/reset-pass',
    method: 'post',
    data
  })
}

// 微信扫码登录
export function chatWxScanLogin (data) {
  return request({
    url: '/chat/wx-scan-login',
    method: 'post',
    data
  })
}

// 自动注册
export function chatAutoRegister (data) {
  return request({
    url: '/chat/auto-register',
    method: 'post',
    data
  })
}

// 获取账号名称
export function chatGetUserName (params) {
  return request({
    url: '/chat/get-user-name',
    method: 'get',
    params
  })
}

// 获取图形码
export function chatHelpCaptcha (params) {
  return request({
    url: '/chat/help/captcha',
    method: 'get',
    params
  })
}

// 公众号登录
export function chatWxOfficalLogin (data) {
  return request({
    url: '/chat/wx-offical-login',
    method: 'POST',
    data
  })
}

// 配置信息
export function chatConfig (params) {
  return request({
    url: '/chat/help/config',
    method: 'get',
    params
  })
}

// 配置信息
export function chatPromotionInfo (params) {
  return request({
    url: '/chat/user/promotion-info',
    method: 'get',
    params
  })
}

export function GoogleLogin (data) {
  return request({
    url: '/chat/login/google',
    method: 'post',
    data
  })
}
export function AoogleLogin (data) {
  return request({
    url: '/chat/login/apple',
    method: 'post',
    data
  })
}
